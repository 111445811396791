import store from '.'
import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import { StoreNames } from '@/config'
import { Matrix, Quadrant } from '@/Models'

@Module()
class moduleCMS extends VuexModule {
  private _matrixSelected: string =
    localStorage.getItem(StoreNames.CMSMATRIX) ?? '{}'
  private _quadrantSelected: any | null = localStorage.getItem(
    StoreNames.CMSQUADRANT,
  )
    ? (JSON.parse(
        localStorage.getItem(StoreNames.CMSQUADRANT) || '{}',
      ) as Quadrant)
    : null
  private _quadrantSelectedId: number | null = localStorage.getItem(
    StoreNames.CMSCONTENTID,
  )
    ? JSON.parse(localStorage.getItem(StoreNames.CMSCONTENTID) || '-1')
    : null

  @Mutation
  setQuadrantId(payload: number) {
    this._quadrantSelectedId = payload
    localStorage.setItem(
      StoreNames.CMSCONTENTID,
      JSON.stringify(this._quadrantSelectedId),
    )
  }
  get quadrantId(): number | null {
    return this._quadrantSelectedId
  }

  @Mutation
  setQuadrantSelected(payload: Quadrant[]) {
    this._quadrantSelected = payload
    localStorage.setItem(
      StoreNames.CMSQUADRANT,
      JSON.stringify(this._quadrantSelected),
    )
  }

  get quadrantSelected(): any[] {
    return this._quadrantSelected
  }

  @Mutation
  setMatrixSelectedId(payload: Matrix) {
    console.log('setMatrixSelected', payload)
    this._matrixSelected = JSON.stringify(payload)
    localStorage.setItem(
      StoreNames.CMSMATRIX,
      JSON.stringify(this._matrixSelected),
    )
  }

  get matrixSelected(): Matrix {
    console.log(this._matrixSelected)
    return JSON.parse(this._matrixSelected) as Matrix
  }
}
const ModuleCMS = new moduleCMS({ store, name: 'moduleCMS' })
export default ModuleCMS
